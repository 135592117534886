.autocomplete-container input,
.autocomplete-control {
  @apply box-border;
}

.autocomplete-control {
  @apply m-6 bg-white;
}

.autocomplete-container {
  @apply bg-brand-secondary-lighter;
}

.autocomplete-container input {
  @apply h-10 w-full px-3 text-lg;
}

.autocomplete-container .custom-list {
  @apply m-0 w-full list-none p-0;
}

.autocomplete-container .custom-list-item {
  @apply p-2;
}

.autocomplete-container .custom-list-item:hover {
  @apply cursor-pointer bg-black;
}

.pac-container {
  @apply bg-brand-secondary-lighter mt-2 p-3;
}

.pac-item {
  @apply bg-brand-secondary-lighter;
  @apply px-1 py-2;
}

.pac-item:hover {
  @apply bg-brand-secondary-light;
}

.pac-arrow-item {
  @apply text-sm font-medium leading-7;
}

.pac-arrow-icon {
  @apply mr-1 mt-[6px] inline-block h-[20px] w-[20px] align-top;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Location'%3E%3Cpath id='Glyph' fill-rule='evenodd' clip-rule='evenodd' d='M11.2695 13.5329L4.9955 11.2921C3.64548 10.81 3.67622 8.89016 5.04099 8.45148L19.041 3.95148C20.2247 3.571 21.3308 4.71311 20.9126 5.88403L15.9126 19.884C15.4221 21.2575 13.4584 21.1939 13.0577 19.7916L11.2695 13.5329Z' fill='%23343538'/%3E%3C/g%3E%3C/svg%3E");
  @apply bg-[length:20px_20px];
}
